module.exports = {
  siteTitle: 'Jen Seo', // Site title.
  siteTitleAlt: '', // Alternative site title for SEO.
  siteLogo: '', // Logo used for SEO and manifest.
  siteUrl: 'http://jseo.studio/', // Domain of your website without pathPrefix.
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: '', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  googleTagManagerID: '', // GTM tracking ID.
  disqusShortname: '', // Disqus shortname.
  userName: '',
  userTwitter: '',
  userLocation: '',
  userDescription: '',
  copyright: '', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#d8b35e', // Used for setting manifest and progress theme colors.
  backgroundColor: '#d8b35e', // Used for setting manifest background color.
}
