import React from 'react'
import { Link } from 'gatsby'
import * as config from '../../../config'
import Headroom from 'react-headroom'

const headerStyle = {
  width: '100%',
  zIndex: '15',
  //background: 'rgba(212,212,212,0.9)',
  borderBottom: 'solid 1px'
};
const headerText = {
  fontFamily: 'Nunito',
  color: 'rgb(216,179,94)',
};
const logoImg = {
  overflow:'visible',
}
const Header = () => (
<Headroom>
  <header className='bg-white black-80 pv2 tc avenir' style={headerStyle}>
    {/* <ScrollAnimation /> */}
    {/* <ScrollAnimation className='mt1 mb0 baskerville i fw1 f1 center' />*/}
    <svg style= {logoImg} className='w6 h6' role='img' viewBox='0 0 270.6 351.' xmlns='http://www.w3.org/2000/svg'
        fill='#03020A'>
        <title>Shot by Seo</title>
        <g>
	<path class="st0" d="M127.4,36.8v-4h5.3v4.4c0,1.2,0.8,2,2,2s2-0.8,2-2V9.6h5.4v27.2c0,4.5-2.9,7.4-7.4,7.4
		C130.3,44.1,127.4,41.2,127.4,36.8z M141.2,36.8V10.5h-3.6v26.7c0,1.7-1.2,2.9-2.9,2.9s-2.9-1.2-2.9-2.9v-3.5h-3.6v3.1
		c0,3.9,2.6,6.5,6.5,6.5C138.6,43.3,141.2,40.6,141.2,36.8z"/>
	<path class="st0" d="M129.5,59.1h13.8v5h-8.5v9.5h6.7v5h-6.7V88h8.5v5h-13.8V59.1z M142.4,92.1v-3.2h-8.5V77.7h6.7v-3.2h-6.7V63.2
		h8.5V60h-12.1v32.1L142.4,92.1L142.4,92.1z"/>
	<path class="st0" d="M127.8,108.6h4.4l5.9,16.6v-16.6h5.4v33.9H139l-5.8-16.5v16.5h-5.4L127.8,108.6L127.8,108.6z M132.3,141.7
		v-20.6l7.3,20.6h2.9v-32.1H139v20.7l-7.4-20.6h-2.9v32.1L132.3,141.7L132.3,141.7z"/>
</g>
<g>
	<path class="st0" d="M85.1,86.2v-4h5.4v4.4c0,1.2,0.8,2,2,2c1.2,0,2-0.8,2-2V84c0-5.6-9.4-7.4-9.4-15.6v-2.7c0-4.5,2.9-7.4,7.4-7.4
		c4.4,0,7.4,2.9,7.4,7.4v4h-5.4v-4.4c0-1.2-0.8-2-2-2c-1.2,0-2,0.8-2,2v2.6c0,5.5,9.4,7.4,9.4,15.6v2.7c0,4.5-2.9,7.4-7.4,7.4
		C88,93.5,85.1,90.6,85.1,86.2z M98.9,86.2v-2.6c0-7.9-9.4-9.5-9.4-15.7v-2.6c0-1.7,1.2-2.9,2.9-2.9c1.7,0,2.9,1.2,2.9,2.9v3.5h3.6
		v-3.1c0-3.9-2.6-6.5-6.5-6.5c-3.9,0-6.5,2.6-6.5,6.5v2.6c0,7.9,9.4,9.5,9.4,15.7v2.6c0,1.7-1.2,2.9-2.9,2.9s-2.9-1.2-2.9-2.9V83H86
		v3.1c0,3.9,2.6,6.5,6.5,6.5C96.4,92.6,98.9,90.1,98.9,86.2z"/>
	<path class="st0" d="M171.2,86.2V65.6c0-4.5,2.9-7.4,7.4-7.4c4.4,0,7.4,2.9,7.4,7.4v20.5c0,4.5-2.9,7.4-7.4,7.4
		C174.1,93.5,171.2,90.6,171.2,86.2z M185,86.2V65.6c0-3.9-2.6-6.5-6.5-6.5s-6.5,2.6-6.5,6.5v20.5c0,3.9,2.6,6.5,6.5,6.5
		S185,90.1,185,86.2z M175.6,86.6V65.3c0-1.7,1.2-2.9,2.9-2.9s2.9,1.2,2.9,2.9v21.3c0,1.7-1.2,2.9-2.9,2.9S175.6,88.3,175.6,86.6z
		 M180.5,86.6V65.3c0-1.2-0.8-2-2-2s-2,0.8-2,2v21.3c0,1.2,0.8,2,2,2C179.7,88.6,180.5,87.8,180.5,86.6z"/>
</g>
</svg>
{/* <h1 className='mt0 mb0 nunito fw1 f12' style={headerText}>J</h1>
    <h1 className='mt1 mb0 nunito fw1 f12' style={headerText}>SEO</h1>
    <h1 className='mt1 mb0 mb0 nunito fw1 f12' style={headerText}>N</h1>
    }
    <nav className='bt bb tc mw8 center mt4'>
      <Link className='f6 f5-l link bg-animate black-80 hover-bg-lightest-blue dib pa3 ph4-l' to='/'>Home</Link>
      <Link className='f6 f5-l link bg-animate black-80 hover-bg-light-pink dib pa3 ph4-l' to='/search'>Search</Link>
      <Link className='f6 f5-l link bg-animate black-80 hover-bg-light-green dib pa3 ph4-l' to='/about'>About</Link>
      <Link className='f6 f5-l link bg-animate black-80 hover-bg-light-yellow dib pa3 ph4-l' to='/contact'>Contact</Link>
    </nav>
*/}
  </header>
</Headroom>
)

export default Header
